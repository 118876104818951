@import './color';

ul.honor-tabs {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0px;

    li {
        display: inline;
        text-align: center;
        background-color: $gray-40-color;
        border-radius: 20px;
        margin-right: 5px;
        padding: 6px 6px;
        
        a {
            font-size: 13px;
            text-decoration: none;
            opacity: 0.8;
        }

        &:hover,
        &.active{
            border: 2px solid $gray-40-color;
            border-radius: 20px;
            background-color: $white-color;
            opacity: 1;
            color: $pv-color;
        }
    }
}