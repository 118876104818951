@import './color';

ul.full-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin: 0;
    padding: 0;

    li {
        display: inline;

        text-align: center;

        a {
            // font-size: 2vh;
            font-weight: bold;
            color: #000 ;

            text-decoration: none;
        }

        &:hover a,
        &.active a {
            border-bottom: 3px solid $pv-color;
        }
    }
}

ul.tabs {
    // position: absolute;
    // bottom: 100%;
    // left: 0;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    display: flex;

    .tab-item {
        padding: 5px 30px 5px;
        font-weight: normal;
        background-color: $gray-40-color;
        color: $gray-60-color;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        display: flex;
        align-items: center;

        cursor: pointer;

        &.active {
            background-color: $gray-60-color;
            color: #fff;
        }
    }
}

.custom-scroll {
    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar {
        width: 4px;
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
        background-color: #555;
    }

    &.scroll-white {
        &::-webkit-scrollbar-thumb {
            background-color: #fff;
        }
    }
}

.btn-circle {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    position: relative;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: $pv-color;

    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #eee, rgb(212, 212, 212));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    // &::before {
    //     content: " ";
    //     position: absolute;
    //     display: inline-block;
    //     height: 24px;
    //     width: 24px;
    //     top: -2px;
    //     left: -2px;
    //     background-color: #000;
    // }
}
ul.custom-tabs {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    li {
        display: inline;

        text-align: center;

        a {
            // font-size: 2vh;
            font-weight: bold;
            color: #000 !important;

            text-decoration: none;
        }

        &:hover a,
        &.active a {
            border-bottom: 3px solid $pv-color;
        }
    }
}