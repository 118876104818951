@import "./color";

$sidebar-width: calc(100vh / 6 + 40px);
$sidebar-margin: 20px;

.pos-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: #fff;

  // font-size: 1.5vh;
  font-size: 0.9rem;
}

.pos-sidebar {
  width: $sidebar-width;
  height: 94vh;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  box-shadow: 0 0 10px $gray-80-color;

  z-index: 3;

  .btn-sidebar-sm,
  .btn-sidebar-lg {
    position: relative;
    padding: 0;
    display: block;
    background-color: transparent;
    width: 100%;

    color: $gray-60-color;
    box-sizing: border-box;
    border: 2px solid;

    font-weight: normal;

    border-radius: 10px;

    text-align: center;
    text-decoration: none;

    &::after {
      content: "";
      display: block;
    }

    &.pv {
      border-color: $pv-color;
    }
    &.rp {
      border-color: $rp-color;
    }
    &.tp {
      border-color: $tp-color;
    }
    &.cv {
      border-color: $cv-color;
    }
    &.sp {
      border-color: $sp-color;
    }
    &.nn {
      border-color: $nn-color;
    }

    &.active,
    &:hover {
      color: #fff;

      &.pv {
        background: $pv-color;
        border-color: $pv-color;
      }

      &.rp {
        background: $rp-color;
        border-color: $rp-color;
      }

      &.tp {
        background: $tp-color;
        border-color: $tp-color;
      }

      &.cv {
        background: $cv-color;
        border-color: $cv-color;
      }

      &.sp {
        background: $sp-color;
        border-color: $sp-color;
      }

      &.nn {
        background: $nn-color;
        border-color: $nn-color;
      }
    }

    &:hover:disabled,
    &:disabled,
    &.disabled,
    &.disabled:hover {
      color: $gray-40-color;
      border-color: $gray-40-color;
      background: transparent;
    }

    & > * {
      position: absolute;
    }
  }

  .btn-sidebar-sm {
    font-size: 1.5vh;

    i {
      left: 20%;
      top: 50%;

      transform: translate(-50%, -50%);

      svg {
        width: 2.5vh;
        height: 2.5vh;
      }
    }

    span {
      left: 40%;
      top: 50%;

      text-align: left;

      transform: translateY(-50%);
    }

    &::after {
      padding-top: 28%;
    }
  }

  .btn-sidebar-lg {
    border-width: 3px;

    font-size: 2.5vh;

    i {
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: lighter;

      svg {
        width: 6vh;
        height: 6vh;
      }
    }

    p {
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      margin: 0;
    }

    &::after {
      padding-top: 90%;
    }
  }

  a > .btn-sidebar-sm {
    margin-top: 16px;
  }

  .btn-sidebar-lg + .btn-sidebar-lg {
    margin-top: 16px;
  }

  .hr {
    margin: $sidebar-margin 0;
  }
}

.pos-top-menu,
.pos-order-header,
.pos-order-search,
.pos-order-detail,
.pos-order-footer {
  width: 100%;
  padding: 0 20px;

  position: relative;

  transition-duration: 1s;
}

.pos-order-header,
.pos-order-search,
.pos-order-footer {
  padding: 10px 20px;
}

.pos-top-menu {
  height: 6vh;
  // @media screen and (max-width: 1024px) {
  //     height: 10vh;
  //     padding-top: 8px;
  //     padding-bottom: 8px;
  // }
}

.pos-order-header {
  height: 15vh;
}

.pos-order-search {
  height: 10vh;

  &.show-items {
    height: 25vh;
  }

  .pos-order-show-items {
    height: 15vh;
    overflow-y: auto;
  }
}

.pos-order-detail {
  height: 54vh;
  z-index: 2;

  &.show-items {
    height: 39vh;
  }

  padding: 0;

  table {
    height: 100%;
    display: block;
    overflow-y: auto;
    position: relative;
    empty-cells: show;

    // thead {
    //     position: fixed;
    // }

    // tbody {
    //     margin-top: 35px;
    // }

    // thead,
    // tbody {
    //     display: block;
    //     position: relative;
    //     width: 100%;

    //     overflow-y: scroll;

    //     // tr {
    //     //     display: flex;
    //     // }
    // }

    // tr {
    //     width: 100%;
    //     display: flex;
    // }

    // td,
    // th {
    //     flex-basis: 100%;
    //     flex-grow: 2;
    //     display: block;
    // }

    // tbody {
    //     height: 100%;
    // }

    // overflow-y: auto;

    // tbody {
    //     display: block;
    //     height: 100%;

    //     overflow-y: auto;

    //     tr {
    //         width: 100%;
    //         display: table-row;

    //         td {
    //             display: table-cell;
    //         }
    //     }
    // }
  }
}

.pos-order-footer {
  height: 15vh;
  font-size: 16px;
  // height: 8vh;
  // @media screen and (max-height: 1440px) {
  //   height: 10vh;
  // }
  // @media screen and (max-height: 1080px) {
  //   height: 12vh;
  // }
  // @media screen and (max-height: 900px) {
  //   height: 13vh;
  // }
  // @media screen and (max-height: 768px) {
  //   height: 14vh;
  // }
}

.pos-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  & + label {
    display: flex;
    position: relative;
    padding-left: 3vh;
    padding-right: 10px;
    cursor: pointer;
    user-select: none;
    margin: 0;

    align-items: center;

    font-weight: bold;

    color: #000;

    span {
      z-index: 2;
    }

    i {
      &::before {
        content: "";
        position: absolute;
        height: 2vh;
        width: 2vh;
        border-radius: 50%;
        background: linear-gradient(
          to right,
          rgba(246, 246, 246, 1) 0%,
          rgba(237, 237, 237, 1) 100%
        );

        box-shadow: 2px 0 3px gray;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }

      position: absolute;
      left: 0;
      height: 2.5vh;
      width: 2.5vh;
      background-color: #fff;
      border-radius: 50%;

      &::after {
        content: "";
        position: absolute;
        display: none;

        width: 1vh;
        height: 1vh;
        border-radius: 50%;
        background-color: $pv-color;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }
  }

  &:checked + label::before,
  & + label:hover::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2.5vh;
    background-color: $pv-color;
    border-radius: 50em;

    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  & + label:hover i::after,
  &:checked + label i::after {
    display: block;
  }

  &.disabled {
    & + label {
      cursor: no-drop;
    }
    & + label::before,
    & + label:hover::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2.5vh;
      border-radius: 50em;

      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: $gray-40-color;
    }

    & + label:hover i::after,
    &:checked + label i::after {
      display: none;
    }
  }
}

// .pos-radio {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;

//     &+label {
//         display: flex;
//         position: relative;
//         padding-left: 35px;
//         padding-right: 10px;
//         cursor: pointer;
//         // font-size: 22px;
//         user-select: none;
//         margin: 0;

//         align-items: center;

//         line-height: 32px;

//         font-weight: bold;

//         color: #000;

//         span {
//             z-index: 2;
//         }

//         i {
//             &::before {
//                 content: "";
//                 position: absolute;
//                 height: 22px;
//                 width: 22px;
//                 border-radius: 50%;
//                 background: linear-gradient(to right, rgba(246, 246, 246, 1) 0%, rgba(237, 237, 237, 1) 100%);
//                 top: 3px;
//                 left: 3px;
//                 box-shadow: 2px 0 3px gray;
//             }

//             position: absolute;
//             // top: 0;
//             left: 0;
//             height: 28px;
//             width: 28px;
//             background-color: #fff;
//             border-radius: 50%;

//             &::after {
//                 content: "";
//                 position: absolute;
//                 display: none;

//                 width: 12px;
//                 height: 12px;
//                 border-radius: 50%;
//                 background-color: $pv-color;
//                 top: 8px;
//                 left: 8px;
//             }
//         }
//     }

//     &:checked+label::before,
//     &+label:hover::before {
//         content: "";
//         position: absolute;
//         width: 100%;
//         height: 28px;
//         left: 0;
//         top: 2px;
//         background-color: $pv-color;
//         border-radius: 14px;
//     }

//     &+label:hover i::after,
//     &:checked+label i::after {
//         display: block;
//     }
// }

.pos-btn-icon {
  display: flex;
  position: relative;

  align-items: center;
  justify-content: center;

  border-radius: 50%;
  // width: 28px;
  // height: 28px;

  width: 2.5vh;
  height: 2.5vh;

  border: none;
  color: $pv-color;
  background-color: #fff;

  // line-height: 32px;

  &::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    // height: 22px;
    // width: 22px;
    height: 2vh;
    width: 2vh;
    // top: 3px;
    // left: 3px;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    ); //linear-gradient(to right, rgba(246, 246, 246, 1) 0%, rgba(237, 237, 237, 1) 100%);
    box-shadow: 2px 0 3px gray;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  span {
    display: flex;

    align-items: center;
    justify-content: center;
    z-index: 1;
  }
}

// .pos-btn-icon {
//     display: flex;
//     position: relative;

//     align-items: center;
//     justify-content: center;

//     border-radius: 50%;
//     width: 28px;
//     height: 28px;
//     border: none;
//     color: $pv-color;
//     background-color: #fff;

//     line-height: 32px;

//     &::before {
//         content: "";
//         position: absolute;
//         border-radius: 50%;
//         height: 22px;
//         width: 22px;
//         top: 3px;
//         left: 3px;
//         background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%); //linear-gradient(to right, rgba(246, 246, 246, 1) 0%, rgba(237, 237, 237, 1) 100%);
//         box-shadow: 2px 0 3px gray;
//     }

//     span {
//         display: flex;

//         align-items: center;
//         justify-content: center;
//         z-index: 1;
//     }
// }

.custom-scroll {
  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  &.scroll-white {
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }
}

.pos-btn {
  border: 1px solid #fff;
  padding: 7px 14px;
  border-radius: 6px;

  &.pos-btn-sm {
    font-size: 1vh;
    padding: 5px 10px;
    border-radius: 4px;
  }

  &.pos-btn-lg {
    font-size: 2.5vh;
    padding: 10px 50px;
    border-radius: 10px;
  }
}

.pos-tabs {
  position: absolute;
  bottom: 100%;
  left: 0;
  // width: 100%;
  // overflow: scroll;

  display: flex;

  .pos-tab-item {
    padding: 8px 30px 5px;
    font-weight: bold;
    background-color: $gray-40-color;
    color: $gray-60-color;
    z-index: 2;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    display: flex;
    align-items: center;

    cursor: pointer;

    &.active {
      background-color: $gray-60-color;
      color: #fff;
    }
  }
}

.pos-card-icon {
  position: relative;

  .icon-top {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    color: $gray-60-color;
    background-color: $gray-40-color;

    font-size: 2vh;
  }

  .pos-table-icon {
    width: 100%;
    color: #000;
    font-size: 1.5vh;

    tr {
      td {
        padding: 5px;

        &:first-child {
          background-color: $gray-40-color;
          padding-left: 15px;
          padding-right: 10px;
          font-weight: bold;
          text-align: right;
          white-space: nowrap;
        }

        &:last-child {
          background-color: $gray-20-color;
          padding-right: 15px;
          padding-left: 10px;
          width: 100%;
        }
      }

      &:first-child {
        td {
          padding-top: 20px;

          &:last-child {
            border-top-right-radius: 20px;
          }
        }
      }

      &:last-child {
        td {
          padding-bottom: 20px;

          &:first-child {
            border-bottom-left-radius: 20px;
          }

          &:last-child {
            border-bottom-right-radius: 20px;
          }
        }
      }
    }
  }
}

ul.pos-fulltabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100%-100px);

  margin: 0 50px;
  padding: 0;

  li {
    display: inline;

    text-align: center;

    a {
      font-size: 2vh;
      font-weight: bold;
      color: #000 !important;

      text-decoration: none;
    }

    &:hover a,
    &.active a {
      border-bottom: 3px solid $pv-color;
    }
  }
}

.pos-payment {
  height: calc(100vh - 6vh);
  padding-top: 50px;

  .pos-payment-inner {
    position: relative;
    height: 100%;
    width: 100%;

    .pos-payment-detail {
      display: flex;
      height: 100%;

      .pos-payment-bill,
      .pos-payment-summary {
        height: 100%;
        overflow-y: auto;
      }

      .pos-payment-bill {
        width: 60%;
      }

      .pos-payment-summary {
        width: 40%;
      }
    }
  }
}

.item-card {
  position: relative;
  padding-top: 100%;

  background-color: #fff;
  border-radius: 5px;

  img {
    max-width: 80%;
    max-height: 80%;

    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  .item-view-detail {
    position: absolute;
    z-index: 1;
    right: 5%;
    bottom: 5%;
  }

  .item-info {
    position: absolute;
    left: 5%;
    top: 5%;
    padding: 0;
    margin: 0;
    font-size: 0.8em;
    width: 90%;
    z-index: 2;
    span {
      display: block;
      width: fit-content;
      max-width: 100%;
    }
  }
  .item-disabled {
    filter: grayscale(100%);
  }
  .item-sold-out {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    z-index: 1;
    > span {
      border-radius: 5px;
      padding: 2px;
      border: 2px solid;
      background-color: white;
      color: $red-color;
      transform: rotate(-24deg);
      font-weight: bold;
      font-size: 0.8em;
    }
  }
}

table.table-member-point {
  tbody {
    tr {
      td {
        border-bottom: 2px solid $gray-60-color;

        position: relative;
      }
    }
  }
}

.circle-point {
  height: 20px;
  width: 20px;
  position: relative;

  border-radius: 50%;

  justify-content: center;
  align-items: center;

  font-size: 0.8em;

  display: flex;
}

.table-promotion {
  border-bottom: 20px solid $gray-60-color;

  tbody {
    tr + tr {
      border-top: 1px solid $gray-60-color;
    }
  }
}

.pos-operator {
  .ant-upload.ant-upload-select {
    width: 100% !important;
  }
}

.set-link-bom {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  &:hover {
    border-color: $pv-color;
  }
}
