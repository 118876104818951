@import "../color";

.form {
  * {
    //   &.ant-select-selection--single .ant-select-selection__rendered,
    //   &.ant-input-number-disabled .ant-input-number-input,
    .ant-input[disabled] {
      color: #000 !important;
    }
  }
  color: #000 !important;
}
