@import "../color";

.tab {
  display: flex;
  width: 100%;
  .item {
    padding: 8px 16px;
    width: 20%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: ease 0.1s;
    background-color: $gray-60-color;
    border-left: 1px solid $white-color;
    border-right: 1px solid $white-color;
    cursor: pointer;
    &:hover {
      background-color: $pv-color;
    }
    &.loading {
      cursor: no-drop;
      &:hover {
        background-color: $gray-60-color;
      }
    }
    > span {
      color: $white-color;
      font-weight: bold;
      user-select: none;
    }
    &.active {
      background-color: $white-color;
      > span {
        color: $black-color;
      }
      &:hover {
        background-color: inherit;
      }
    }
    .radio {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 16px;
      background-color: $white-color;
      box-sizing: border-box;
      box-shadow: 2px 1px 3px 1px rgba(0, 0, 0, 0.4);
      &.draft {
        background-color: $yellow-color;
      }
      &.pending {
        background-color: $pv-color;
      }
    }
  }
}
