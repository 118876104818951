@import "variables";

.cursor-pointer {
  cursor: pointer;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-default {
  cursor: default;
}

.object-fit-cover {
  object-fit: cover;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.text-decoration-none {
  text-decoration: none;
}

.text-bolder {
  font-weight: bolder;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.min-width-0 {
  min-width: 0 !important;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-600 {
  max-width: 600px;
}

.max-width-800 {
  max-width: 800px;
}

.min-width-150 {
  min-width: 150px;
}

.min-width-100 {
  min-width: 100px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.white-space-nowrap {
  white-space: nowrap;
}

.width-auto {
  width: auto;
}

.width-125 {
  width: 125px;
}

.hidden {
  display: none;
}

.border-radius-16 {
  border-radius: 16px;
}
