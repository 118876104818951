@import "../color";

$new-upload-size: 100px;
$auth-doc-upload-width: 100%;
$auth-doc-upload-height: 400px;

@mixin upload-btn() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  float: left;
  user-select: none;
  @content;
}

@mixin upload-add($icon-font-size) {
  .add {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 0.2s;
    background-color: $black-color;
    opacity: 0;
    .icon {
      cursor: pointer;
      font-size: $icon-font-size;
      color: $white-color;
    }
    &:hover {
      opacity: 0.8;
    }
    @content;
  }
}

@mixin upload-img($icon-font-size, $width, $height) {
  padding: 0 !important;
  position: relative;
  .delete {
    transition: 0.2s;
    background-color: $black-color;
    opacity: 0;
    .icon {
      cursor: pointer;
      font-size: $icon-font-size;
      color: $white-color;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: $width;
    height: $height;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @content;
}

@mixin upload-block() {
  width: $new-upload-size;
  height: $new-upload-size;
  overflow: hidden;
  margin: 8px;
  border: 1px solid $gray-60-color;
  &.img {
    @include upload-img(24px, $new-upload-size, $new-upload-size) {
      &.commit {
        border: 1px solid $pv-color;
      }
    }
  }
  &.btn {
    @include upload-btn();
    @include upload-add(24px) {
      width: $new-upload-size;
      height: $new-upload-size;
    }
  }
  @content;
}

.new-upload {
  .block {
    @include upload-block();
  }
}

.auth-doc {
  .block {
    width: $auth-doc-upload-width;
    height: $auth-doc-upload-height;
    overflow: hidden;
    margin-top: 32px;
    border: 1px solid $gray-60-color;
    &.btn {
      @include upload-btn();
      @include upload-add(32px) {
        width: $auth-doc-upload-width;
        height: $auth-doc-upload-height;
      }
    }
    &.img {
      @include upload-img(32px, $auth-doc-upload-width, 100%);
      height: 100%;
    }
  }
}

.claim-auth-doc {
  .block {
    @include upload-block();
  }
}

.take {
  &.claim-detail {
    .block {
      height: 128px;
      border-right: 2px solid $gray-60-color;
      margin-bottom: 16px;
      &.tricker {
        @media only screen and (max-width: 1599px) {
          border-right: none;
        }
      }
      &.border-none {
        border-right: none;
      }
      &.space-detail-title {
        padding-top: 26px;
      }
    }
  }
}

.block-drop-down {
  ::-webkit-scrollbar {
    width: 0px;
  }
  .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid $gray-50-color;
    user-select: none;
    transition: ease 0.2s;
    &:hover {
      background-color: $gray-20-color;
      cursor: pointer;
    }
  }
  .block-scroll {
    max-height: 800px;
    transition: ease 0.2s;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 16px;
    &.collapse {
      max-height: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

.product-type-tab {
  .item {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-60-color;
    transition: ease 0.2s;
    > span {
      color: $white;
    }
    &:hover {
      cursor: pointer;
    }
    &.active {
      background-color: $pv-color;
    }
  }
}
