@import "./variables";
$pv-color: #97d700;
$pv-color-light: #cce2a3;
$rp-color: #f6ab1b;
$rp-color-light: #fdd78f;
$tp-color: #5fbfec;
$tp-color-light: #b2e1f8;
$cv-color: #ed68a7;
$cv-color-light: #f7b6d2;
$sp-color: #9254de;
$sp-color-light: #d3adf7;
$nn-color: #f5222d;
$nn-color-light: #ffa39e;

$ra-color: #c59533;

$gray-80-color: #4d4e4e;
$gray-60-color: #838383;
$gray-50-color: #d9d9d9;
$gray-40-color: #e3e3e3;
$gray-20-color: #f0efef;

$red-color: #ed1c24;
$yellow-color: #fadb14;

$black-color: #000;
$white-color: #fff;

.bg-body {
  background-color: $body-bg !important;
}

.bg-pv {
  background-color: $pv-color !important;
}

.bg-pv-light {
  background-color: $pv-color-light !important;
}

.bg-rp {
  background-color: $rp-color !important;
}

.bg-rp-light {
  background-color: $rp-color-light !important;
}

.bg-tp {
  background-color: $tp-color !important;
}

.bg-tp-light {
  background-color: $tp-color-light !important;
}

.bg-cv {
  background-color: $cv-color !important;
}

.bg-cv-light {
  background-color: $cv-color-light !important;
}

.bg-sp {
  background-color: $sp-color !important;
}

.bg-sp-light {
  background-color: $sp-color-light !important;
}

.bg-nn {
  background-color: $nn-color !important;
}

.bg-nn-light {
  background-color: $nn-color-light !important;
}

.bg-ra {
  background-color: $ra-color !important;
}

.bg-gray-80 {
  background-color: $gray-80-color !important;
}

.bg-gray-60 {
  background-color: $gray-60-color !important;
}

.bg-gray-40 {
  background-color: $gray-40-color !important;
}

.bg-gray-20 {
  background-color: $gray-20-color !important;
}

.bg-reds {
  background-color: $red-color !important;
}

.bg-yellows {
  background-color: $yellow-color !important;
}

.text-pv {
  color: $pv-color !important;
}

.text-pv-light {
  color: $pv-color-light !important;
}

.text-rp {
  color: $rp-color !important;
}

.text-rp-light {
  color: $rp-color-light !important;
}

.text-tp {
  color: $tp-color !important;
}

.text-tp-light {
  color: $tp-color-light !important;
}

.text-cv {
  color: $cv-color !important;
}

.text-cv-light {
  color: $cv-color-light !important;
}

.text-sp {
  color: $sp-color !important;
}

.text-sp-light {
  color: $sp-color-light !important;
}

.text-nn {
  color: $nn-color !important;
}

.text-nn-light {
  color: $nn-color-light !important;
}

.text-ra {
  color: $ra-color !important;
}

.text-gray-80 {
  color: $gray-80-color !important;
}

.text-gray-60 {
  color: $gray-60-color !important;
}

.text-gray-50 {
  color: $gray-50-color !important;
}

.text-gray-40 {
  color: $gray-40-color !important;
}

.text-gray-20 {
  color: $gray-20-color !important;
}

.text-reds {
  color: $red-color !important;
}

.text-yellows {
  color: $yellow-color !important;
}

.border-pv {
  border-color: $pv-color !important;
}

.border-pv-light {
  border-color: $pv-color-light !important;
}

.border-rp {
  border-color: $rp-color !important;
}

.border-rp-light {
  border-color: $rp-color-light !important;
}

.border-tp {
  border-color: $tp-color !important;
}

.border-tp-light {
  border-color: $tp-color-light !important;
}

.border-cv {
  border-color: $cv-color !important;
}

.border-cv-light {
  border-color: $cv-color-light !important;
}

.border-sp {
  border-color: $sp-color !important;
}

.border-sp-light {
  border-color: $sp-color-light !important;
}

.border-nn {
  border-color: $nn-color !important;
}

.border-nn-light {
  border-color: $nn-color-light !important;
}

.border-ra {
  border-color: $ra-color !important;
}

.border-gray-80 {
  border-color: $gray-80-color !important;
}

.border-gray-60 {
  border-color: $gray-60-color !important;
}

.border-gray-40 {
  border-color: $gray-40-color !important;
}

.border-gray-20 {
  border-color: $gray-20-color !important;
}

.border-reds {
  border-color: $red-color !important;
}

.border-yellows {
  border-color: $yellow-color !important;
}
