@import "../color";

.document-upload .block-btn-upload {
   width: 100%;
   height: 50px;
   border: 1px dashed $gray-60-color;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 6px;
   &:hover {
      border: 1px dashed $pv-color;
   }
   .file-name {
      font-size: 12px;
      cursor: default;
   }
   .file-size {
      font-size: 10px;
      cursor: default;
      color: $gray-60-color;
   }
   .btn-del {
      cursor: pointer;
      color: $gray-60-color;
      &:hover {
         color: $pv-color;
      }
   }
}

.select-search {
   position: relative;
   width: 100%;
   .btn-search {
      color: $gray-60-color;
      position: absolute;
      top: 10px;
      right: 12px;
      cursor: pointer;
      &.small{
         top: 6px;
         right: 10px;
      }
   }
   .btn-cancel {
      color: $gray-60-color;
      position: absolute;
      top: 10px;
      right: 36px;
      cursor: pointer;
      &.small{
         top: 6px;
         right: 30px;
      }
   }
}

.qr-pop-up {
   .header {
      position: relative;
      width: 100%;
      .bar {
         background-color: $gray-60-color;
         display: flex;
         justify-content: flex-end;
         .input {
            display: flex;
            align-items: center;
            width: 600px;
            padding: 8px;
            > span {
               white-space: nowrap;
               color: $white-color;
            }
         }
      }
      .tab {
         width: 150px;
         padding: 8px;
         text-align: center;
         background-color: $gray-60-color;
         border-top-left-radius: 24px;
         border-top-right-radius: 24px;
         > span{
            color: $white-color;
         }
      }
   }
   .block-head{
      padding-right: 4px;
   }
   .block-scroll{
      margin-top: 8px;
      max-height: 560px;
      overflow-y: scroll;
   }
}
