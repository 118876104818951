$green-legacy: #97d700;

// custom scrollbar

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 6px;
}

:hover {
  body::-webkit-scrollbar-thumb {
    background: #aaa !important;
  }

  body::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }
}

.BG-White {
  background-color: #fff;
}

.BG-Login {
  background-color: #858585;
}

.custom-from-check {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #97d700;
    background-color: #97d700;
  }
}

// == custom Color Legacy : button
.btn-green-legacy {
  color: #fff;
  background-color: $green-legacy;
  border-color: $green-legacy;
  box-shadow: 0;
}

.bg-green-legacy {
  background-color: $green-legacy;
}

.bg-transparent {
  background-color: transparent;
}

.color-green-legacy {
  color: $green-legacy;
}

// ==== custom login
.login-custom {
  .login.login-v2 {
    textarea:focus,
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="datetime"]:focus,
    input[type="datetime-local"]:focus,
    input[type="date"]:focus,
    input[type="month"]:focus,
    input[type="time"]:focus,
    input[type="week"]:focus,
    input[type="number"]:focus,
    input[type="email"]:focus,
    input[type="url"]:focus,
    input[type="search"]:focus,
    input[type="tel"]:focus,
    input[type="color"]:focus,
    .uneditable-input:focus {
      border-color: transparent;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset, 0 0 0 rgba(126, 239, 104, 0.6);
      outline: 0 none;
    }

    .custom-input-login {
      border-radius: 6px;
      background-color: #f1f1f1;

      i {
        color: #7d7e78;
        font-size: 14px;
      }
    }

    .custom-input-login:focus-within {
      border-radius: 6px;
      border-color: #007bff !important;
      box-shadow: 0 0 5px #007bff !important;
    }

    .is-invalid {
      border: 1px solid #ff5b57;
    }

    .login-panel {
      background-color: #fff;
      border-radius: 10px;
    }

    .login-logo {
      background-color: #96c63c;
      border-radius: 40px 40px 40px 40px;
      text-align: center;
      padding: 15px 20px 15px 20px;

      i {
        font-size: 50px;
        color: #fff;
      }
    }

    .login-title {
      font-size: 18px;
      color: #858585;
    }

    .login-buttons {
      button {
        font-size: 18px;
        background-color: #96c63c;
        border-color: #96c63c;
      }
    }
  }
}

// ---- trip maldives

.maldives-trip {
  input {
    font-size: 14px;
    padding-left: 15px;
  }

  p {
    font-size: 14px;
    color: #979797;
  }

  th {
    text-align: center;
    color: #979797;
    font-size: 16px;
  }

  td {
    padding: 5px 15px 5px 15px;

    span {
      font-size: 16px;
    }

    input {
      align-items: center;
    }
  }
}

.maldives-trip-taps {
  .app-theme-dark-darker .app-sidebar .nav > li.active > a,
  .bg-dark-darker {
    background-image: linear-gradient(to right, #b57e10, #f9df7b, #b57e10);
  }

  .app-theme-dark .app-sidebar .nav > li.active > a,
  .bg-dark {
    background-color: transparent !important;
  }

  .card {
    border: 0px solid;
  }

  .card-header:first-child {
    border-radius: 15px;
  }
}

.maldives-trip-taps-dark {
  .card-header {
    background-color: #363636;
  }
}

.maldives-trip-line {
  height: 3px;
  background-image: linear-gradient(to right, #b57e10, #f9df7b, #b57e10);
}

.input-maldives {
  border-radius: 10px;
  border: 0px;
}

// custom Cascader
.rc-cascader-menu {
  display: inline-block;
  width: auto !important;
  min-width: 100px !important;
  height: 192px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-right: 1px solid #e9e9e9;
  overflow: auto;
}

// table
.table-fixed {
  width: 100%;
  background-color: #fff;

  tbody {
    //   height:200px;
    overflow-y: auto;
    width: 100%;
  }

  thead,
  tbody,
  tr,
  td,
  th {
    display: block;
  }

  tbody {
    td {
      float: left;
    }
  }

  thead {
    tr {
      th {
        float: left;
      }
    }
  }
}

.section-product {
  a {
    color: gray;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: none;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.ant-notification {
  z-index: 1082;
}

.ant-calendar-picker-container,
.ant-select-dropdown {
  z-index: 1081;
}

.pos-document-upload-btn .ant-upload {
  width: inherit;
  height: inherit;
}

.ant-message {
  z-index: 1021;
}

.ant-tooltip {
  z-index: 1081;
}

.modal-with-tab {
  .ant-tabs-tab {
    user-select: none;
  }
}

.ant-table-absolute-title-center {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}

.ant-table-pagination-center {
  .ant-table {
    width: 100%;
  }
  .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.table-very-small {
  .ant-table-row {
    > td {
      font-size: 10px;
    }
  }
  .ant-table-column-title {
    font-size: 10px;
  }
  &.with-input {
    .ant-input-number {
      width: 100%;
      font-size: 10px;
    }
    .ant-input {
      font-size: 10px;
    }
  }
}

.form-small {
  .ant-form-item-label {
    label {
      font-size: 12px !important;
      margin-bottom: 0;
    }
  }

  .ant-form-item-label,
  .ant-form-item-control {
    line-height: normal;
  }
}
