@import "../color";

.promotion-list {
  height: 300px;
  padding: 16px;
  .card {
    position: relative;
    border: 1px solid $gray-50-color;
    border-radius: 8px;
    overflow: hidden;
    height: 268px;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body {
      opacity: 0;
      transition: 0.2s ease;
      &:hover {
        opacity: 1;
      }
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
      &.collapse {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
      }
      .add {
        height: 0;
        cursor: pointer;
        overflow: hidden;
        &.collapse {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .content {
        max-height: 300px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        &.collapse {
          max-height: 0;
        }
        > span {
          font-size: 10px;
        }
        .source {
          height: 150px;
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
          margin-bottom: 16px;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          > span {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.master {
  .card {
    position: relative;
    border: 1px solid $gray-50-color;
    border-radius: 8px;
    overflow: hidden;
    height: 200px;
    width: 200px;
    .add {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .body {
    opacity: 0;
    transition: 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $black-color;
    .icon {
      cursor: pointer;
      font-size: 24px;
      color: $white-color;
    }
  }
}

@mixin card-promotion-img() {
  width: 100%;
  height: 240px;
  user-select: none;
  cursor: pointer;
  position: relative;
  &.disabled > img {
    filter: grayscale(100%);
    cursor: no-drop;
  }
  .failed {
    @include icon-status() {
      color: $nn-color;
    }
  }
  .warn {
    @include icon-status() {
      color: $rp-color;
    }
  }
  @content;
}

@mixin card-promotion-content() {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-top: 1px solid $gray-50-color;
  @content;
}

@mixin card-condition-content() {
  overflow-y: scroll;
  height: 250px;
  padding: 16px;
  border-left: 1px solid $gray-50-color;
  @content;
}

@mixin icon-status() {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  @content;
}

@mixin rec-promotion-card($border-color) {
  border: 1px solid $border-color;
  transition: 0.1s ease-out;
  border-radius: 5px;
  overflow: hidden;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  &.active {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  &.disabled {
    border: 1px solid $gray-50-color;
  }
  @content;
}

.rec-promotion {
  &.card-promotion {
    @include rec-promotion-card($gray-50-color) {
      .img {
        @include card-promotion-img();
        .success {
          @include icon-status() {
            color: $pv-color;
          }
        }
      }
      .content {
        @include card-promotion-content();
      }
    }
  }
  &.card-condition {
    @include rec-promotion-card($pv-color) {
      .img {
        width: 100%;
        height: 250px;
        cursor: pointer;
        user-select: none;
        position: relative;
        &.disabled {
          filter: grayscale(100%);
          cursor: no-drop;
        }
        &.default {
          cursor: default;
        }
        .success {
          @include icon-status() {
            color: $pv-color;
          }
        }
      }
      .content {
        @include card-condition-content();
      }
    }
  }
}

.promotion-list2 {
  &.card-promotion {
    @include rec-promotion-card($gray-50-color) {
      .img {
        @include card-promotion-img();
        .tab-view-img {
          position: absolute;
          bottom: 10px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          > div {
            background-color: rgba(0, 0, 0, 0.7);
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid $gray-50-color;
            &:hover {
              background-color: #000;
            }
          }
        }
      }
      .content {
        @include card-promotion-content();
      }
    }
  }
  &.card-condition {
    @include rec-promotion-card($pv-color) {
      .img {
        width: 100%;
        height: 250px;
        user-select: none;
        position: relative;
        &.disabled {
          filter: grayscale(100%);
        }
      }
      .content {
        @include card-condition-content();
      }
    }
  }
}
