@import "../color";

.legal .page-menu-tab {
   background-color: $gray-20-color;
   border-radius: 8px;
   border: 1px solid $gray-50-color;
   overflow: hidden;
   margin-bottom: 16px;
   .col {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      cursor: pointer;
      border-right: 1px solid $gray-50-color;
      color: $black-color;
      font-size: 16px;
      &.active {
         background-color: $pv-color;
         color: $white-color;
         > span,
         > svg {
            color: $white-color;
            user-select: none;
         }
      }
      &.last {
         border-right: none;
      }
   }
}

.legal .header {
   height: 80px;
   background-color: $white-color;
   display: flex;
   align-items: center;
   padding: 32px;
   margin-bottom: 16px;
   display: flex;
   justify-content: space-between;
   > span {
      font-size: 24px;
   }
}

.legal .container {
   padding: 32px;
}

.legal .profile {
   background-color: $white-color;
   border-radius: 8px;
   .menu {
      .col {
         cursor: pointer;
         height: 50px;
         display: flex;
         justify-content: center;
         align-items: center;
         border-bottom: 3px solid $gray-50-color;
         > span {
            font-size: 16px;
            user-select: none;
         }
         &.active {
            border-bottom: 4px solid $pv-color;
         }
         &:hover {
            background-color: $gray-20-color;
         }
      }
   }
   .row {
      padding: 16px;
      &.divider {
         border-bottom: 1px solid $gray-60-color;
      }
   }
   .main-data {
      border-bottom: 1px solid $gray-60-color;
      .col-other {
         display: flex;
         flex-direction: column;
         justify-content: space-around;
         height: 100%;
         padding-top: 50px;
      }
   }
   .skeleton {
      padding: 16px;
   }
}

.legal .commission {
   background-color: $white-color;
   border-radius: 8px;
   padding: 16px;
}

.legal .document {
   background-color: $white-color;
   border-radius: 8px;
   padding: 16px;
   display: flex;
   .list {
      overflow: scroll;
      width: 100%;
      max-height: 700px;
      padding-right: 8px;
      border-right: 1px solid $gray-60-color;
      .data-content {
         display: flex;
         flex-direction: column;
         align-items: center;
         overflow: hidden;
         padding: 8px;
         margin: 8px;
         .img-preview {
            cursor: pointer;
            width: 100px;
            height: 100px;
            border-radius: 10px;
            overflow: hidden;
            margin: 6px;
            box-shadow: 0 0 6px 1px $gray-50-color;
            &:hover {
               box-shadow: 0 0 6px 1px $pv-color;
            }
            &.active {
               box-shadow: 0 0 6px 1px $pv-color;
            }
         }
      }
   }
   .preview {
      overflow: scroll;
      width: 100%;
      max-height: 700px;
      padding-left: 8px;
      border-left: 1px solid $gray-60-color;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
         margin: 16px;
      }
   }
}

.legal .history {
   background-color: $white-color;
   border-radius: 8px;
   padding: 16px;
}
