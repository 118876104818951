@import "../color";

$upload-size: 100px;
$upload-size-small: 32px;
$icon-font-size: 20px;
$icon-font-size-small: 16px;

@mixin icon($font-size) {
  .icon {
    cursor: pointer;
    font-size: $font-size;
    color: $white-color;
  }
  @content;
}

@mixin icon-in($font-size) {
  .icon-in {
    font-size: $font-size - 4px;
    @content;
  }
}

@mixin upload-btn() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  float: left;
  user-select: none;
  @content;
}

@mixin upload-add($font-size) {
  .add {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 0.2s;
    background-color: $black-color;
    opacity: 0;
    &:hover {
      opacity: 0.8;
    }
    @include icon($font-size);
    @content;
  }
}

@mixin upload-img($width, $height, $font-size) {
  padding: 0 !important;
  position: relative;
  .delete {
    position: absolute;
    top: 0px;
    left: 0px;
    width: $width;
    height: $height;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 0.2s;
    background-color: $black-color;
    opacity: 0;
    &:hover {
      opacity: 0.8;
    }
    @include icon($font-size);
  }
  .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: $width;
    height: $height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @content;
}

@mixin upload-file-list-height($height, $font-size) {
  .block {
    width: $upload-size;
    height: $height;
    overflow: hidden;
    margin: 8px;
    border: 1px solid $gray-60-color;
    padding: 0;
    &.img {
      @include upload-img($upload-size, $height, $font-size) {
        &.commit {
          border: 1px solid $pv-color;
        }
        &.default {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
        }
      }
    }
    &.btn {
      @include icon-in($font-size);
      @include upload-btn();
      @include upload-add($font-size) {
        width: $upload-size;
        height: $height;
      }
    }
    &.disabled {
      background-color: $gray-20-color;
      border: 1px solid $gray-50-color;
      cursor: no-drop;
    }
    .file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 100%;
        text-align: center;
        font-size: 4px;
        padding-left: 4px;
        padding-right: 4px;
      }
      @include icon-in($font-size);
    }
    @content;
  }
}

.upload-file-list {
  @include upload-file-list-height($upload-size, $icon-font-size);
}

.upload-file-list-small {
  @include upload-file-list-height($upload-size-small, $icon-font-size-small);
}

.upload-file {
  width: 100%;
  height: 50px;
  border: 1px dashed $gray-60-color;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px dashed $pv-color;
  }
  &.error {
    border: 1px dashed $nn-color;
  }
  &.disabled {
    background-color: $gray-20-color;
    &:hover {
      border: 1px dashed $gray-60-color;
    }
  }
  .icon {
    font-size: $icon-font-size;
    &.commit {
      color: $pv-color;
    }
  }
  .btn {
    font-size: $icon-font-size - 4;
    cursor: pointer;
    &:hover {
      color: $pv-color;
    }
    &.disabled {
      color: $gray-60-color;
      cursor: not-allowed;
      &:hover {
        color: $gray-60-color;
      }
    }
  }
  .overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file-name {
    font-size: 12px;
    cursor: default;
  }
  .file-size {
    font-size: 10px;
    cursor: default;
    color: $gray-60-color;
  }
  .block-upload {
    display: flex;
    width: 100%;
    height: 100%;
    span {
      user-select: none;
    }
  }
}
